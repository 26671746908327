<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>

<v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
            <v-row style="margin-top:20px">
              <v-col cols="12" sm=12 md=12>
                <v-file-input
                  outlined
                  v-model="mobile.file"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Nom de Version *"
                  outlined
                  required
                  v-model=mobile.version_name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Numéro de Version *"
                  required
                  outlined
                  v-model=mobile.version_code
                ></v-text-field>
              </v-col>
              
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
  <v-card>
  <v-card-title>

    </v-card-title>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="text-left">Nom de Version</th>
          <th class="text-center">Numéro de Version</th>
          <th class="text-right">APK</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left" style="vertical-align:middle">{{ rows.version_name }}</td>
          <td class="text-center" style="vertical-align:middle">{{ rows.version_code }}</td>
          <td class="text-right"><a class="btn btn-success" target="_blank" style="color:white!important" v-bind:href="current_domain+rows.url" download><v-icon style="color:white">mdi-download</v-icon></a></td>
        </tr>
      </tbody>
    </table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'APKView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/mobile')
        .then(function (response) {
          Object.assign(self.rows, response.data.result)
          self.enable = true          
        }).catch(function(error){
          console.log(error)
        })
    },
      save: function(){
        var self = this
        this.loading = true
        var url = '/mobile'
        Object.assign(this.$axios.defaults.headers, {'Content-Type': 'multipart/form-data'})
        var formdata = new FormData();
        formdata.append('file', this.mobile.file);
        var config = {
          method: 'post',
          url: url+"?version_name="+this.mobile.version_name+"&version_code="+this.mobile.version_code,
          data : formdata
        };
        this.$axios(config)
          .then(response => {
            console.log(response.data.result)
            Object.assign(self.rows, response.data.result)
            self.dialog = false
            self.loading = false
            self.reset()
        })
      },
      close: function(){
        this.dialog = false
        this.reset()
        this.loading = false
      },
      reset: function(){
        this.mobile = {
          version_name: '', 
          version_code: '', 
          file: [], 
        }
        this.index = -1
        this.form_title = 'Nouvelle APK'
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.reset()
        })
      },
  },
  mounted(){
    this.load()
  },
  data: () => ({
      search: '',
      current_domain: 'https://api.keslakay.com/',
      index: -1,
      loading: false,
      form_title: "Nouvelle APK",
      items: [
        {
          text: 'Gestion APK',
          disabled: false,
          href: '/apk',
        }
      ],
      fluid: true,
      enable: false,
      rows: {},
      roles: [],
      dialog:'',
      dialogDelete: false,
      mobile: {
        version_name: '', 
        version_code: '', 
        file: [], 
      }
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#F68D3A !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #1976d2 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:18px;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-text-field__details{
    display:none!important;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
  .v-card__actions .v-progress-circular circle{
    color:white!important;
  }
  .v-text-field--enclosed .v-input__append-inner{
    margin-top:20px!important;
  }
</style>